.author {
    font-size: 14px;
    color: rgb(247, 247, 0);
}

.message {
    font-size: 14px;
    color: #fff;
}

.otherAuthor {
    font-size: 14px;
    color: #f5fcec
}