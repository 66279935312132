.root {
    position: absolute;
    left: 25px;
    width: 381px;
    height: 394px;
    bottom: 84px;
    border-radius: 10px;
    padding: 16px;
    z-index: 10;
    background-color: rgba(29, 29, 29, 0.548);
    display: flex;
    flex-direction: column;
}

.title {
    color: #fff;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.messagesContainer {
    margin-bottom: 10px;
    background-color: rgba(109, 109, 109, 0.658);
    border-radius: 6px;
    overflow-y: scroll;
    height: 273px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    display: flex;
    flex-direction: column-reverse;
}

.form {
    display: flex;
    height: 35px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #FFF;
}

.formContainer {
    position: relative;
    margin-top: auto;
    height: 30px;
}

.input {
    width: 100%;
    background-color: none;
    background: none;
    min-width: 100%; 
    max-width: 100%;
    border:none;
}

.input::placeholder {
    color: #B3B3B3;
}

.formContainer .form .input {
    background: none !important;
    color: #fff;
    border:none !important;
    backdrop-filter: none;
}

.collapseButton {
    position: absolute;
    width: 48px;
    height: 48px;
    padding: 6px 12px;
    left: 24px;
    border: none;
    bottom: 24px;
    border-radius: 30px;
    opacity: 0.95;
    background: var(--secondary-800, #333);
}

.sendButton {
    width: 35px;
    padding: 0px;
}

.emojiPicker {
    position: absolute;
    bottom: 40px;
    left: -15px;
}

@media only screen and (max-width: 800px) {
    .root {
        bottom: 145px;
    }

    .collapseButton {
        bottom: 85px;
    }
}

@media only screen and (max-width: 420px) {
    .root {
        left: 5px;
        width: 360px;
    }

    .collapseButton {
        bottom: 85px;
    }
}